<template lang="pug">
div.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" userFilter="analyticalEntriesFilters"  :filterCollaborators="filterCollaborators" :filterAffairs="filterAffairs"  :dateRangeDefault="dateRangeDefault" :addButton="addButton" :searchButton="searchButton" :selectedRows="selectedRows" @setDates="setDates" @setCollaborators="setCollaborators" @setAffairs="setAffairs" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="setSelectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch" :isMobile="isMobile" :viewModeButton="viewModeButton" :viewMode="viewMode" @updateViewMode="updateViewMode")
    .w-100.d-flex.flex-column#scrollContent(style="overflow-x: scroll;" :style="'height: calc(100% - 58px);'")
        .text-center.flex-center.w-100(v-if='isLoadingAffairCostingsList || !headerLoaded || !dataLoaded')
            .loading-bg-inner
                .loader
                    .outer
                    .middle
                    .inner
            .mt-5
                br
                br
                br
                | Chargement de la liste des écritures analytiques...
        sync-grid(v-else ref="overviewgrid" uid="analyticalEntries" :gridData="dataOrigine" :name="name" :allowPaging="allowPaging" :allowSorting='true' :headerData="headerData" :editSettings="editSettings" :allowGrouping="allowGrouping"  @setSelectedRows="setSelectedRows" :commandClick='commandClick' :groupSettings="groupSettings" :sortSettings="sortOptions" @setSelectedRowsIndexes="setSelectedRowsIndexes" @actionBegin="actionBegin")
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  formatNumber,
  formatCurrency,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";
import { isMobile as checkIfMobile } from "@/types/api-orisis/library/GlobalOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var weekday = require("dayjs/plugin/weekday");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
dayjs.extend(weekday);

import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";
import SyncTreeGrid from "@/components/global/grid/TreeGrid.vue";
import SyncGrid from "@/components/global/grid/Grid.vue";
import { documentApplicationType } from "@/types/api-orisis/enums/enums";

export default {
  components: {
    HeaderGrid,
    SyncTreeGrid,
    SyncGrid,
  },
  data() {
    return {
      searchText: "",
      indexSearch: 0,
      name: "analytical-entries",
      uid: "analyticalEntries",
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      selectedRowsIndexes: [],
      dateRangeDefault: [
        new Date(dayjs().startOf("week")),
        new Date(dayjs().endOf("week")),
      ],
      collaboratorIds: [],
      affairIds: [],
      currentDate: new Date(),
      viewModeButton: true,
      viewMode: "compare",
      dateRanger: true,
      filterCollaborators: true,
      filterAffairs: true,
      addButton: true,
      searchButton: true,
      searchPlaceholder: "Rechercher une écriture",
      allowFiltering: true,
      allowPaging: true,
      allowGrouping: true,
      dataLoaded: true,
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showDeleteConfirmDialog: true,
        mode: "Normal",
        newRowPosition: "Bottom",
        allowNextRowEdit: true,
        titleAdd: "Nouvelle écriture",
        titleEdit: "Modifier l'écriture",
        titleField: "",
      },
      commands: [
        {
          type: "addRow",
          buttonOption: {
            cssClass: "e-flat e-addbutton",
            iconCss: "e-plus e-icons",
          },
        },
        {
          type: "duplicate",
          buttonOption: {
            cssClass: "e-flat e-duplicatebutton",
            iconCss: "e-copy e-icons",
          },
        },
        {
          type: "Delete",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-delete e-icons",
          },
        },
        {
          type: "Save",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-save e-icons",
          },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
      selectionSettings: {
        type: "Multiple",
        enableSimpleMultiRowSelection: false,
      },
      groupSettings: {
        captionTemplate: "captionTemplate",
        columns: [],
      },
      sortOptions: {
        columns: [{ field: "displayLabel", direction: "Descending" }],
      },
      isMobile: checkIfMobile(),
    };
  },
  async activated() {
    this.setHeaderData();
    this.indexSearch++;
    this.headerLoaded = true;
  },
  computed: {
    ...mapGetters([
      "collaboratorsList",
      "affairCostingsList",
      "affairCostingsListByAffair",
      "isLoadingAffairCostingsList",
      "analyticsSettingsList",
      "isLoadingAnalyticsSettingsList",
      "collaboratorsList",
      "affairsList",
      "missionsList",
      "workspaceSelected",
      "userParametersList",
    ]),
    missionsListFiltered() {
      return this.$store.getters["missionsList"].map((elem) => {
        return {
          ...elem,
          parentId: elem.parentId == 0 ? null : elem.parentId,
          hasChild: this.missionsList.filter(
            (child) => child.parentId == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    analyticsSettingsListFiltered() {
      return this.$store.getters["analyticsSettingsList"].map((elem) => {
        return {
          ...elem,
          parentAccountNumber:
            elem.parentAccountNumber == 0 ? null : elem.parentAccountNumber,
          hasChild: this.analyticsSettingsList.filter(
            (child) =>
              child.parentAccountNumber == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    dataOrigine() {
      return this.affairCostingsList
        .filter((elem) => {
          if (this.viewMode == "forecast") {
            return elem.sourceType == 2;
          }
          if (this.viewMode == "counted") {
            return elem.sourceType == 1;
          } else {
            return true;
          }
        })
        .filter((elem) => {
          if (
            (this.affairIds && this.affairIds.length > 0) ||
            (this.collaboratorIds && this.collaboratorIds.length > 0)
          ) {
            if (
              this.affairIds &&
              this.affairIds.length > 0 &&
              this.collaboratorIds &&
              this.collaboratorIds.length > 0
            ) {
              return (
                this.affairIds.includes(elem.affairId) &&
                this.collaboratorIds.includes(elem.collaboratorId)
              );
            } else {
              return (
                this.affairIds.includes(elem.affairId) ||
                this.collaboratorIds.includes(elem.collaboratorId)
              );
            }
          } else {
            return true;
          }
        });
    },
  },
  async created() {
    Promise.all([
      this.getAnalyticsSettings({}),
      this.getMissions({}),
      this.getAffairs({}),
      this.getCollaborators({}),
      this.getCompanies({}),
    ]).then(() => {
      this.dataLoaded = true;
      this.headerLoaded = true;
    });

    let filter = this.userParametersList.find(
      (el) => el.key == "analyticalEntriesFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
      });
    }
  },
  methods: {
    ...mapActions([
      "getAnalyticsSettings",
      "getAffairCostings",
      "getAffairs",
      "getMissions",
      "getCollaborators",
      "getCompanies",
    ]),
    formatNumber,
    formatCurrency,
    setColumnsByObject,
    comparer(a, b) {
      if (a.displayLabel < b.displayLabel) {
        return -1;
      }
      if (a.displayLabel > b.displayLabel) {
        return 1;
      }
      return 0;
    },
    updateViewMode(val) {
      this.selectedRows = [];

      if (this.viewMode !== val) {
        this.dataLoaded = false;
        this.headerData.forEach((cell) => {
          if (val == "counted") {
            if (cell.mode && cell.mode == "counted") {
              cell.visible = true;
            } else if (cell.mode && cell.mode !== "counted") {
              cell.visible = false;
            }
          } else if (val == "forecast") {
            if (cell.mode && cell.mode == "forecast") {
              cell.visible = true;
            } else if (cell.mode && cell.mode !== "forecast") {
              cell.visible = false;
            }
          }

          if (cell.columns && cell.columns.length > 0) {
            cell.columns.forEach((subCell) => {
              if (val == "counted") {
                if (subCell.mode && subCell.mode == "counted") {
                  subCell.visible = true;
                } else if (subCell.mode && subCell.mode !== "counted") {
                  subCell.visible = false;
                }
              } else if (val == "forecast") {
                if (subCell.mode && subCell.mode == "forecast") {
                  subCell.visible = true;
                } else if (subCell.mode && subCell.mode !== "forecast") {
                  subCell.visible = false;
                }
              } else {
                subCell.visible = true;
              }
            });
          }
        });
        this.$nextTick(() => {
          this.dataLoaded = true;
        });
      }

      this.viewMode = val;
    },
    changeViewDates(dates, refreshViewMode = false) {
      if (dates.force) {
        this.setDates(dates);
      } else if (refreshViewMode) {
        let startDate = this.dateRangeDefault[0];
        let endDate = this.dateRangeDefault[1];
        this.setDates({ startDate: startDate, endDate: endDate });
      } else {
        let startDate = this.dateRangeDefault[0];
        let endDate = this.dateRangeDefault[1];
        if (this.dateRangeDefault[0] > dates.startDate) {
          startDate = dates.startDate;
        }
        if (this.dateRangeDefault[1] < dates.endDate) {
          endDate = dates.endDate;
        }
        if (
          this.dateRangeDefault[0] !== startDate &&
          this.dateRangeDefault[1] !== endDate
        ) {
          this.setDates({ startDate: startDate, endDate: endDate });
        }
      }
    },
    setHeaderData() {
      this.headerData = [
        {
          type: "string",
          field: "date",
          headerText: "Mois",
          width: 140,
          maxWidth: 140,
          template: "monthTemplate",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          defaultValue: new Date(),
          showInColumnChooser: true,
          visible: true,
          edit: { type: "monthpicker" },
        },
        {
          type: "string",
          field: "sourceType",
          headerText: "Origine",
          filterTemplate: "sourceTypeFilterTemplate",
          textAlign: "Center",
          width: 100,
          minWidth: 100,
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          template: "sourceTypeTemplate",
          valueAccessor: "dropdown",
          textAlign: "Center",
          defaultValue: 2,
          edit: {
            source: [
              { label: "Réalisé", id: 1 },
              { label: "Prévu", id: 2 },
            ],
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
            name: "sourceType",
          },
        },
        {
          type: "string",
          field: "directionType",
          filterTemplate: "directionTypeFilterTemplate",
          headerText: "Type",
          width: 90,
          minWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          template: "directionTypeTemplate",
          valueAccessor: "dropdown",
          textAlign: "Center",
          defaultValue: 1,
          edit: {
            source: [
              { label: "Entrée", id: 1 },
              { label: "Sortie", id: 2 },
            ],
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
            name: "directionType",
          },
        },
        {
          type: "number",
          field: "affairId",
          headerText: "Affaire",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.affairsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdowndependencies",
            name: "dropdownaffair",
            target: "dropdownmission",
            uid: "viewEditReportAffair",
          },
        },
        {
          type: "string",
          field: "documentApplicationType",
          filterTemplate: "documentApplicationTypeFilterTemplate",
          headerText: "Source",
          width: 90,
          minWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          foreignKeyValue: "id",
          edit: {
            source: documentApplicationType,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
            name: "documentApplicationType",
          },
        },
        {
          type: "number",
          field: "missionId",
          headerText: "Mission",
          width: 200,
          minWidth: 200,
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: false,
          valueAccessor: "dropdown",
          edit: {
            source: this.missionsListFiltered,
            fields: {
              text: "label",
              value: "id",
              parentValue: "parentId",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.missionsListFiltered,
            },
            type: "dropdowntreedependencelabel",
            name: "dropdownmission",
          },
        },
        {
          type: "number",
          field: "analyticsSettingId",
          headerText: "Code analytique lié",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.analyticsSettingsListFiltered,
            fields: {
              text: "displayLabel",
              value: "id",
              parentValue: "parentAccountNumber",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.analyticsSettingsListFiltered,
            },
            type: "dropdowntree",
            name: "dropdowntreeanalytics",
          },
          // validationRules: { required: true },
        },
        {
          type: "string",
          field: "label",
          headerText: "Libellé",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          allowGrouping: false,
          visible: true,
          edit: { type: "textbox", name: "textboxlabel" },
        },
        {
          type: "number",
          field: "collaboratorId",
          headerText: "Collaborateur",
          minWidth: 160,
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          filterTemplate: "collaboratorFilterTemplate",
          valueAccessor: "dropdown",
          foreignKeyValue: "id",
          dataSource: this.collaboratorsList,
          edit: {
            source: this.collaboratorsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdown", //<<FIX
            name: "collaboratorId",
          },
          visible: true,
        },
        {
          type: "number",
          format: "formatNumber",
          field: "quantity",
          headerText: "Qté réelle",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 1,
          editType: "numericedit",
          edit: { params: { decimals: 2 } },
          // validationRules: { required: true },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "valuatedQuantity",
          headerText: "Qté valorisée",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 1,
          editType: "numericedit",
          edit: { params: { decimals: 2 } },
          // validationRules: { required: true },
        },
        {
          type: "number",
          field: "amountUnit",
          headerText: "Mt Unitaire",
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          editType: "numericedit",
          edit: { params: { decimals: 2, step: 0.05 } },
          valueAccessor: "currency",
          // validationRules: { required: true },
        },
        {
          type: "number",
          field: "amount",
          headerText: "Mt Total",
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: false,
          allowFiltering: false,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          valueAccessor: "sumAffairCostingCurrencyFormatter",
        },
        {
          type: "string",
          field: "documentApplicationType",
          headerText: "Type Document lié",
          width: 140,
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "documentPath",
          headerText: "URL Document lié",
          width: 140,
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: false,
        },
        {
          type: "commands",
          commands: this.commands,
          headerText: "Actions",
          width: 210,
          // freeze:"Right"
        },
      ];
    },
    async commandClick(args) {
      if (args.commandColumn.type === "Cancel") {
        this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      } else if (args.commandColumn.type === "duplicate") {
        // const selectedRow =
        // this.$refs.overviewgrid.$refs.overviewgrid.getSelectedRecords();
        // const newReport = { ...selectedRow[0] };
        // newReport.id = 0;
        // newReport.status = -1;
        // newReport.startDate = dayjs(newReport.startDate);
        // newReport.endDate = dayjs(newReport.endDate);
        // newReport.keyIndex = this.genererChaineAleatoire();
        // newReport.eventId = null;
        // newReport.interventionId = null;
        // this.dataFormReports.push(newReport);
        // this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      } else if (args.commandColumn.type === "addRow") {
        // const selectedRow =
        // this.$refs.overviewgrid.$refs.overviewgrid.getSelectedRecords();
        // const newReport = { ...selectedRow[0] };
        // newReport.id = 0;
        // newReport.status = -1;
        // newReport.startDate = dayjs(newReport.startDate);
        // newReport.endDate = dayjs(newReport.endDate);
        // newReport.keyIndex = this.genererChaineAleatoire();
        // newReport.missionId = null;
        // newReport.affairId = null;
        // newReport.payrollEventId = null;
        // newReport.duration = 0;
        // newReport.durationValue = 0;
        // newReport.message = null;
        // newReport.eventId = null;
        // newReport.interventionId = null;
        // this.dataFormReports.push(newReport);
        // this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
    },

    setSelectedRowsIndexes(val) {
      this.selectedRowsIndexes = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    setAffairs(affairIds) {
      this.affairIds = affairIds;
    },
    setCollaborators(collaboratorIds) {
      this.collaboratorIds = collaboratorIds;
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.currentDate = new Date(
          dayjs(dates.startDate).startOf("month").format("YYYY-MM-DD")
        );
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];

        await this.getAffairCostings({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }).then(async (res) => {
          this.setHeaderData();
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    async actionBegin(args) {
      if (args.rowData && args.rowData.status == 3) {
        args.cancel = true;
        return false;
      }
      if (args.requestType === "beginEdit" || args.requestType === "add") {
        // for (
        //     var i = 0;
        //     i < this.$refs.overviewgrid.$refs.overviewgrid.getColumns().length;
        //     i++
        // ) {
        //     if (
        //     this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
        //     "date"
        //     ) {
        //     this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
        //         i
        //     ].visible = false;
        //     }
        //     if (
        //     this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
        //     "status"
        //     ) {
        //     this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
        //         i
        //     ].visible = false;
        //     }
        // }
      }
      if (args.requestType === "save" || args.requestType == "delete") {
        // for (
        //     var i = 0;
        //     i < this.$refs.overviewgrid.$refs.overviewgrid.getColumns().length;
        //     i++
        // ) {
        //     if (
        //     this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
        //     "date"
        //     ) {
        //     this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
        //         i
        //     ].visible = true;
        //     }
        //     if (
        //     this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
        //     "status"
        //     ) {
        //     this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
        //         i
        //     ].visible = true;
        //     }
        // }
      }
      if (args.requestType === "save" && args.action === "edit") {
      }
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {},
    setSelectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "columns") {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
  },
};
</script>
